.contact {
  display: flex;
  flex-direction: column;
}

@keyframes fadeInOut {
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

.contact__status {
  font-size: 1rem;
  margin-top: 2rem;
  font-weight: bold;
  color: var(--clr-primary);
  animation: fadeInOut 3s infinite;
}

